import React from "react";
import '../productdetailcard/ProductDetailCard.css';

const ProductDetailCard = (props)=>{
return(

    <div className={props.classlist} >
        <div className="image-box-1">
        <img src={props.pdImg} alt="" />
        </div>
        <div className="paragraph-box-1">

        <h1 className="card-heading-1">{props.pdHeading}</h1>
        <p className="card-desc-1">{props.pdDesc}</p>
        </div>
         </div>
    )
}


export default ProductDetailCard ;