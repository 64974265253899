import React from 'react';
import './productBottomStyle.css'

function ProductBottom(props) {
    return (

            <div className="pb-bottom">
                
                <div className="pb-color">
                        <h4 className="pb-heading">Color</h4>
                    <div className='pb-color-container'>

                    <div className="color-2"><i class="fa-solid fa-check"></i></div>
                    <div className="color-3"></div>
                    <div className="color-4"></div>
                    <div className="color-5"></div>
                    <div className="color-6"></div>
                    <div className="color-7"></div>
                    <div className="color-8"></div>
                    </div>
                </div>
                <div className='pb-right'>

                <div className="pb-battery">
                    <h2 className='pb-heading'>Range</h2>
                    <h3 className="pb-range pb-text">{props.evRange}</h3>
                </div>
                <div className="pb-charge">
                    <h2 className='pb-heading'>Consumption</h2>
                    <h3 className="pb-chrgeTime pb-text">
                        {props.chargeTime}
                    </h3>
                </div>
                <div className="p-bottom-1">
                {/* <div className='order'> */}
                    <button className='order-btn-1'>Order Now</button>
                    <div className='p-reserve'>Reserve for ₹999 <i style={{marginLeft: "5px"}} class="fa-solid fa-arrow-right"></i></div>
                {/* </div> */}
            </div>
                
                </div>
            </div>
    )
}

export default ProductBottom
