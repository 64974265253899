import React from 'react';
import './hispeedscootersStyle.css';

const HispeedScootersPage = () => {
  return (
    <div>
      <div>HispeedScootersPage</div>

    </div>
  )
}

export default HispeedScootersPage