import React, { useEffect, useState } from "react";
import ProductDetailCard from "../../templateOneComponents/productdetailcard/ProductDetailCard";
import Product from "../../templateOneComponents/product/Product";
import ProductBottom from "../../templateOneComponents/productBottom/ProductBottom";
import '../productdetail/productdetail.css';
import { FaStarHalfAlt, FaArrowRight, FaRupeeSign } from "react-icons/fa";
import HomeCard from '../../templateOneComponents/homeCard/HomeCard';
import pdone from '../../templateOneAssets/productDetailsAssets/dt-3000-1.webp';
import pdtwo from '../../templateOneAssets/productDetailsAssets/dt-3000-3.webp';
import pdthree from '../../templateOneAssets/productDetailsAssets/dt-3000-2.webp';
import pdfour from '../../templateOneAssets/productDetailsAssets/dt-3000-4.webp';

import imageone from '../../templateOneAssets/homePageAssets/bike-img-9.webp';

function ProductDetail() {

  const [Modal, setModal ] = useState(false);

  const toggleModal = ()=> {
   setModal(!Modal)
   document.getElementById('root-1').classList.toggle('back-blur');
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div>
    <div>
      {/* <div className='ss-container'> */}
      <div className="ss-parent margin-top">
        <div className='ss-p17'>
        <img className="bike-image2" src={imageone} alt="" />
          <Product
            leftTxt='Get ready for'
            evName='DT-3000'
           
            rightTxt='Powerful ride!'
          />
        </div>
        <div className="pb-1">
          
        <div className="pb-bottom"  >
        
        <div className="pb-battery">
            <h2 className='pb-heading'>Price :</h2>
            <div className="price-contianer">
          <span className="price"><FaRupeeSign/>1,38,858</span>
          {/* <span className="old-price">90000</span> */}
        </div>
        </div>
        <div className="pb-color">
                <h4 className="pb-heading">Color :</h4>
            <div className='pb-color-container'>

            <div className="color-2"><i class="fa-solid fa-check"></i></div>
            <div className="color-3"></div>
            <div className="color-4"></div>
            <div className="color-5"></div>
            <div className="color-6"></div>
            <div className="color-7"></div>
            <div className="color-8"></div>
            </div>
        </div>
        <div className='pb-right-1'>

        {/* <div className="pb-charge">
            <h2 className='pb-heading'>Consumption</h2>
            <h3 className="pb-chrgeTime pb-text">
                
            </h3>
        </div> */}
        <div className="p-bottom-12">
        {/* <div className='order'> */}
             <button onClick={toggleModal} className="order-btn"> Order Now</button>     
            {/* <div className='p-reserve'>Reserve for ₹999 <i style={{marginLeft: "5px"}} class="fa-solid fa-arrow-right"></i></div> */}
        {/* </div> */}
    </div>
        
        </div>
    </div>
</div>
</div>

      <div className="section-1-top">
        <h1 className="header-d-1">Description :</h1>
        <p className="paragraph-1">
        The KOMAKI DT 3000 Seat offers cushy support to effectively buffer and
ergonomically distribute pressure, making each ride a breeze!
<br />
A continuation of the Komaki legacy,
the Komaki DT 3000 is ready to rule the road.
        </p>
        <h1 className="header-d-1">Warranty :</h1>
        <p className="paragraph-1">
          Komaki warrants all its motorcycles for a period of 5 years or 70000
          Km and scooters for a period of 5 years or 50000 Km, whichever is
          earlier, from the date of purchase.
        </p>

<div className="section-2-top">

        <h1 className="header-d-1"> Highlights:</h1>
        <ul  className="paragraph-1">
          <li>Power Equivalent of a 150cc Class</li>
          <li>Suspension Telescopic Shock Absorber</li>
          <li>Head Lights: LED WITH DRL</li>
          <li>Speakers: Bluetooth & Speakers</li>
        </ul>
</div>
        <h1 className="header-d-1">Product Description :</h1>

</div>
        <div className="product-detail-section">

        <ProductDetailCard  
             pdImg={pdone} 
             classlist='section-div-inner'
             pdHeading="Power Equivalent of a 125cc Class"
             pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
             />

        <ProductDetailCard 
             pdImg={pdtwo}
             classlist='section-div-inner1'
          pdHeading="Free The Limitation"
          pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
          />

        <ProductDetailCard 
             pdImg={pdthree}
             classlist='section-div-inner'
             pdHeading="Superior Suspension"
             pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
             />

        <ProductDetailCard 
             pdImg={pdfour}
             classlist='section-div-inner1'
             pdHeading="CBS Double Disc"
          pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
          />

          </div>
      
          <div className="section-1-top" style={{marginTop :'60px', marginBottom:'-10px'}}>
<h1 className="header-d-1">Similar Products :</h1>
</div>
<section className="h-section-6">
        <div className="h-section-6-card">
          <div className="h-section-6-bg-1">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev1} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Tn95"
            evName="Komaki TN-95"
            rating={4.3}
            review="2k"
            price="1,49,322"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-2">
            {/* <div className="h-section-6-ev">
              <img  id='sec6ev2' src={sec6ev2} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-VeinceEco"
            evName="Komaki venice eco"
            rating={4.2}
            review="2k"
            price="1,05,525"
            // oldPrice="1,05,525"
          />
        </div>

        <div className="h-section-6-card">
          <div className="h-section-6-bg-3">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev3} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-DT-3000"
            evName="Komaki DT-3000"
            rating={4.2}
            review="2k"
            price="1,38,858"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-4">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev4} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Ly"
            evName="Komaki LY"
            rating={4.2}
            review="2k"
            price="1,15,154"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-5">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev5} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Flora"
            evName="Komaki flora"
            rating={4.2}
            review="2k"
            price="1,04,475"
            // oldPrice="85,000"
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-6">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev6} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/productdetail-Veince"
            evName="Komaki Venice"
            rating={4.2}
            review="2k"
            price="1,38,600"
            // oldPrice="1,38,600"
          />
        </div>
      </section>

<div className="deteil-section-1">

        <div> 
          <h1 className="heading-background">Technical Specification :</h1>
          <div className="detail-box-1">
            <div className="detail-box-2">
              <p>Model</p>
              <p>Motor Type</p>
              <p>Transmission</p>
              <p>Electricity Bill</p>
             
            </div>
           
            <div className="detail-box-3">
              <p>Komaki DT-3000</p>
              <p>72V 3000 Watt</p>
  
              <p>Direct Hub Motor</p>
              <p>1.22kwh/100km</p>
      
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">Power</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
              <p>Battery Type</p>
              <p>Range</p>
              <p>Charging Time</p>
            </div>
            <div className="detail-box-3">
              <p>Lithium Battery</p>
              <p>75-100 km</p>
              <p>Lion 4-5hr</p>
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">CHASSIS/ BRAKE/ SUSPENSION</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
            <p>CHASSIS</p>
            <p>Front Brake</p>
            <p>Rear Brake</p>
            <p>Front Suspension</p>
            <p>Rear Suspension</p>
            </div>
            <div className="detail-box-3">
            <p>Highly Resistant Steel</p>
            <p>Disc Brake</p>
            <p>Disc Brake</p> 
            <p>Telescopic Shock Absorber</p>
            <p>Hydraulic Shock Absorber</p>
            </div>
          </div>
        </div>


        <div> 
          <h1 className="heading-background">DIMENSION</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
              <p>Head Lights</p>
              <p>Wheel </p>
              <p>Speakers</p>
            </div>
            <div className="detail-box-3">
              <p>LED WITH DRL</p>
              <p>Alloy Rim</p>
              <p>Bluetooth & Speakers</p>
            </div>
          </div>
        </div>

      </div>
      <br />
      <br />
      <div>
        
        {Modal && (
            <div className="modal-back"> 
          <div className="modal-box">
            <div className="overlay"></div>
            <i onClick={toggleModal} class="fa-solid fa-xmark"></i>
            <h1 className="input-txt-1">Full Name</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Phone Number </h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Email</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Message</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Product Name</h1>
            <select className="delership-input-1" name="" id="">
              <option value="">DT-3000</option>
            </select>

            <div className="btn-box-delership">
              <input
                className="btn-delearship"
                type="button"
                value="Order Now"
              />
            </div>
            </div>
          </div>


        )}
      </div>
    </div>
  );
}

export default ProductDetail;
