import React from 'react';
import './newsCardStyle.css';

function NewsCard(props) {
  return (
    <div className='nc-container'>

        <div className="nc-ev-container">
            <img src={props.image} alt="" className="nc-ev" />
        </div>
        <h1>{props.heading}</h1>
        <p>{props.description}
            <a href="/">Read more</a>
        </p>
    </div>
  )
}

export default NewsCard