import { BrowserRouter  } from 'react-router-dom';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import TemplateOne from './templateOne/TemplateOne';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <TemplateOne />
      </BrowserRouter>
    </div>
  );
}

export default App;
  