import React from 'react';
import './easyrickshawStyle.css';

const EasyRickshawPage = () => {
  return (

    <div>

    <div>EasyRickshawPage</div>

    </div>
  )
}

export default EasyRickshawPage