import React from 'react';
import { Link } from 'react-router-dom';
import './navbarStyle.css'
import logo from "../../templateOneAssets/homePageAssets/logo.svg"


const Navbar = () => {
  return (
    <div className='nav-container'>
      <div className='nav-parent'>
        <div className='nav-child'>
            <div className='navbar-btn'>
            <i class="fa-solid fa-bars"></i>
            </div>
          <div className='nav-logo'>
            <Link className="logo-item" to='/'>
               <img className='logo' src={logo} alt="" />
            </Link>
          </div>
          <div className='profile'>
          
          </div>
     
          <ul className="nav-li">
            <li >
              <Link className="nav-li-item" to='/'>
                HOME
              </Link>
            </li>
            <li >
              <Link className="nav-li-item" to='/smart-scooters'>
                SMART SCOOTERS
              </Link>
            </li>
            <li >
              <Link className="nav-li-item" to='/hi-speed-scooters'>
                HI-SPEED SCOOTERS
              </Link>
            </li>
            <li >
              <Link className="nav-li-item" to='/dealership'>
                DEALERSHIP
              </Link>
            </li>
          </ul>
          {/* </div> */}
         

          <button className="nav-btn">
            BOOK NOW
          </button>
       
        </div>
      </div>
    </div>
  )
}
{/* <script>

function sidebar() {
 console.log('himanshu')
}
</script> */}

export default Navbar


