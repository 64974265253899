import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from './templateOneComponents/footer/Footer';
import Navbar from './templateOneComponents/navbar/Navbar';

//template_One_pages
import HomePage from './templateOnePages/aHome/HomePage';
import SmartScootersPage from './templateOnePages/bSmartscooters/SmartScootersPage';
import HispeedScootersPage from './templateOnePages/cHispeedscooters/HispeedScootersPage';
import EasyRickshawPage from './templateOnePages/dEasyrickshaw/EasyRickshawPage';
import DealershipPage from './templateOnePages/eDealership/DealershipPage';
import ProductDetail1 from './templateOnePages/productdetail/ProductDetail-Veince';
import ProductDetail2 from './templateOnePages/productdetail/ProductDetail-VeinceEco';
import ProductDetail3 from './templateOnePages/productdetail/ProductDetail-Tn95';
import ProductDetail4 from './templateOnePages/productdetail/ProductDetail-Flora';
import ProductDetail5 from './templateOnePages/productdetail/ProductDetail-Ly';
import ProductDetail6 from './templateOnePages/productdetail/ProductDetail-Ranger';
import ProductDetail7 from './templateOnePages/productdetail/ProductDetail-DT-3000';
import ProductDetail8 from './templateOnePages/productdetail/ProductDetail-XGT-X-4';
import ProductDetail9 from './templateOnePages/productdetail/ProductDetail-XGT-X-One';
import ProductDetail10 from './templateOnePages/productdetail/ProductDetail-CAT';
import OtpSignIn from './templateOnePages/otherpages/OtpSignIn'
import UserDetailSignIn from './templateOnePages/otherpages/UserDetailSignIn'
import AddAddressSignIn from './templateOnePages/otherpages/AddAddressSignIn'
import SignInPage from './templateOnePages/otherpages/SignInPage'




const TemplateOne = () => {
  return (
    <div>
      <Navbar />
      <Routes>

        <Route path='/' element={<HomePage />} />

        <Route path='/smart-scooters' element={<SmartScootersPage />} />

        <Route path='/hi-speed-scooters' element={<HispeedScootersPage />} />

        <Route path='/easy-rickshaw' element={<EasyRickshawPage />} />

        <Route path='/dealership' element={<DealershipPage />} />

        <Route path='/productdetail-Veince' element={<ProductDetail1 />} />

        <Route path='/ProductDetail-VeinceEco' element={<ProductDetail2 />} />

        <Route path='/ProductDetail-Tn95' element={<ProductDetail3 />} />

        <Route path='/ProductDetail-Flora' element={<ProductDetail4 />} />

        <Route path='/ProductDetail-Ly' element={<ProductDetail5 />} />

        <Route path='/ProductDetail-Ranger' element={<ProductDetail6 />} />

        <Route path='/ProductDetail-DT-3000' element={<ProductDetail7 />} />

        <Route path='/ProductDetail-XGT-X-4' element={<ProductDetail8 />} />

        <Route path='/ProductDetail-XGT-X-One' element={<ProductDetail9 />} />

        <Route path='/ProductDetail-CAT' element={<ProductDetail10 />} /> 

        <Route path='/SignInPage' element={<SignInPage />} /> 

        <Route path='/OtpSignIn' element={<OtpSignIn />} /> 

        <Route path='/UserDetailSignIn' element={<UserDetailSignIn />} /> 

        <Route path='/AddAddressSignIn' element={<AddAddressSignIn />} /> 

      </Routes>

      <Footer />

    </div>
  )
}

export default TemplateOne