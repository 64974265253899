import React from "react";
import "./footerStyle.css";

//assets
import fb from "../../templateOneAssets/footerAssets/facebook.webp";
import yt from "../../templateOneAssets/footerAssets/youtube.webp";
import ig from "../../templateOneAssets/footerAssets/instagram.webp";

import logo1 from "../../templateOneAssets/footerAssets/logo-1.webp";
import logo2 from "../../templateOneAssets/footerAssets/logo-2.webp";
const Footer = () => {
  return (
    <div className="footer-parent">
      <div className="footer-child">
        <div className="footer-icons-container">
          <div className="logo-box">

          <div className="footer-icon">
            <img src={logo2} alt="" className="footer-icon" />
          </div>
          <div className="footer-icon">
            <img width="100%" src={logo1} alt="" className="footer-icon" />
          </div>
          </div>
          <h1 className="sub-footer">Subscribe to our newsletter</h1>
          <div style={{display: 'flex'}}>
          <input className="footer-input" type="text" name="" id="" />
          <button className="send-btn">Send</button>
          </div>
        </div>
        <div className="grid-box">

        <div className="footer-bottom">
          <h1 className="footer-head">About Us</h1>
          <ul className="footer-li">
            <li className="footer-li-item">Smart scooters</li>
            <li className="footer-li-item">About Eg Prestige</li>
            <li className="footer-li-item">Contact Us</li>
            <li className="footer-li-item">Hi- speed scooters</li>
          </ul>
        </div>
        <div className="footer-bottom">
          <h1 className="footer-head">Corporate</h1>
          <ul className="footer-li">
            <li className="footer-li-item">Media</li>
            <li className="footer-li-item">Responsibilities</li>
            <li className="footer-li-item">Key Policies</li> 
          </ul>
        </div>
        <div className="footer-bottom">
          <h1 className="footer-head">Careers</h1>
          <ul className="footer-li">
            <li className="footer-li-item">Why us</li>
            <li className="footer-li-item">Who are we </li>
            <li className="footer-li-item">Join us</li>
          </ul>
        </div>
        <div className="footer-bottom">
          <h1 className="footer-head">Legal</h1>
          <ul className="footer-li">
            <li className="footer-li-item">Terms & Conditions</li>
            <li className="footer-li-item">Disclaimer</li>
            <li className="footer-li-item">Privacy Policy</li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
