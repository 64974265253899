import React, { useEffect, useState } from "react";
import ProductDetailCard from "../../templateOneComponents/productdetailcard/ProductDetailCard";
import Product from "../../templateOneComponents/product/Product";
import ProductBottom from "../../templateOneComponents/productBottom/ProductBottom";
import '../productdetail/productdetail.css';
import { FaStarHalfAlt, FaArrowRight, FaRupeeSign } from "react-icons/fa";
import HomeCard from '../../templateOneComponents/homeCard/HomeCard';
import pdone from '../../templateOneAssets/productDetailsAssets/xgt-x1-1.webp';
import pdtwo from '../../templateOneAssets/productDetailsAssets/xgt-x1-2.webp';
import pdthree from '../../templateOneAssets/productDetailsAssets/xgt-x1-3.webp';
import pdfour from '../../templateOneAssets/productDetailsAssets/xgt-x1-4.webp';

import imageone from '../../templateOneAssets/homePageAssets/bike-img-8.webp';

function ProductDetail() {
  const [Modal, setModal ] = useState(false);
  
  const toggleModal = ()=> {
    setModal(!Modal)
    document.getElementById('root-1').classList.toggle('back-blur');

  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div>
    <div>
      {/* <div className='ss-container'> */}
      <div className="ss-parent margin-top">
        <div className='ss-p19'>
        <img className="bike-image8" src={imageone} alt="" />
          <Product
               leftTxt='Let’s Race'
               evName='XGT X-One'
               
               rightTxt='New'
          />
        </div>
        <div className="pb-1">
          
        <div className="pb-bottom"  >
        
        <div className="pb-battery">
            <h2 className='pb-heading'>Price :</h2>
            <div className="price-contianer">
          <span className="price"><FaRupeeSign/>86,625
</span>
          {/* <span className="old-price">90000</span> */}
        </div>
        </div>
        <div className="pb-color">
                <h4 className="pb-heading">Color :</h4>
            <div className='pb-color-container'>

            <div className="color-2"><i class="fa-solid fa-check"></i></div>
            <div className="color-3"></div>
            <div className="color-4"></div>
            <div className="color-5"></div>
            <div className="color-6"></div>
            <div className="color-7"></div>
            <div className="color-8"></div>
            </div>
        </div>
        <div className='pb-right-1'>

        {/* <div className="pb-charge">
            <h2 className='pb-heading'>Consumption</h2>
            <h3 className="pb-chrgeTime pb-text">
                
            </h3>
        </div> */}
        <div className="p-bottom-12">
        {/* <div className='order'> */}
             <button onClick={toggleModal} className="order-btn"> Order Now</button>     
            {/* <div className='p-reserve'>Reserve for ₹999 <i style={{marginLeft: "5px"}} class="fa-solid fa-arrow-right"></i></div> */}
        {/* </div> */}
    </div>
        
        </div>
    </div>
</div>
</div>


      <div className="section-1-top">
        <h1 className="header-d-1">Description :</h1>
        <p className="paragraph-1">
          Komaki X1 ia a perfect blend of style and comfort. it is avalible at the most economical price. Equipped 
          with an anti theft lock system this scooter is noise free and enviromental friendly. It is avalible in both 
          lithium and gel battery and is very easy to maintain.
        </p>
        <h1 className="header-d-1">Warranty :</h1>
        <p className="paragraph-1">
          Komaki warrants all its motorcycles for a period of 5 years or 70000
          Km and scooters for a period of 5 years or 50000 Km, whichever is
          earlier, from the date of purchase.
        </p>

<div className="section-2-top">

        <h1 className="header-d-1"> Highlights:</h1>
        <ul  className="paragraph-1">
          <li>Telescopic Shock Absorber and
Hydraulic Shock Absorber</li>
          <li>Up to 12 paise per km</li>
          <li> 1.18-2 KWh per 100 kms</li>
         
        </ul>
</div>
        <h1 className="header-d-1">Product Description :</h1>

</div>
        <div className="product-detail-section">

        <ProductDetailCard  
             pdImg={pdone} 
             classlist='section-div-inner'
             pdHeading="Gel & Lithium ION"
             pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
             />

        <ProductDetailCard 
             pdImg={pdtwo}
             classlist='section-div-inner1'
          pdHeading="Free The Limitation"
          pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
          />

        <ProductDetailCard 
             pdImg={pdthree}
             classlist='section-div-inner'
             pdHeading="Superior Suspension"
             pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
             />

        <ProductDetailCard 
             pdImg={pdfour}
             classlist='section-div-inner1'
             pdHeading="CBS Double Disc"
          pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
          />

          </div>
      
          <div className="section-1-top" style={{marginTop :'60px', marginBottom:'-10px'}}>
<h1 className="header-d-1">Similar Products :</h1>
</div>
<section className="h-section-6">
        <div className="h-section-6-card">
          <div className="h-section-6-bg-1">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev1} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Tn95"
            evName="Komaki TN-95"
            rating={4.3}
            review="2k"
            price="1,49,322"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-2">
            {/* <div className="h-section-6-ev">
              <img  id='sec6ev2' src={sec6ev2} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-VeinceEco"
            evName="Komaki venice eco"
            rating={4.2}
            review="2k"
            price="1,05,525"
            // oldPrice="1,05,525"
          />
        </div>

        <div className="h-section-6-card">
          <div className="h-section-6-bg-3">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev3} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-DT-3000"
            evName="Komaki DT-3000"
            rating={4.2}
            review="2k"
            price="1,38,858"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-4">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev4} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Ly"
            evName="Komaki LY"
            rating={4.2}
            review="2k"
            price="1,15,154"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-5">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev5} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Flora"
            evName="Komaki flora"
            rating={4.2}
            review="2k"
            price="1,04,475"
            // oldPrice="85,000"
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-6">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev6} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/productdetail-Veince"
            evName="Komaki Venice"
            rating={4.2}
            review="2k"
            price="1,38,600"
            // oldPrice="1,38,600"
          />
        </div>
      </section>

       <div className="deteil-section-1">

        <div> 
          <h1 className="heading-background">Technical Specification :</h1>
          <div className="detail-box-1">
            <div className="detail-box-2">
              <p>Model</p>
              <p>Per Charge Consumption</p>
              <p>Riding cost</p>
              <p>Gel & Lithium ION</p>
              <p>Rated Power</p>
            </div>
            <div className="detail-box-3">
              <p>Komaki X-ONE</p>
              <p>1-1.15 Units</p>
              <p>Up to 12 paise per km</p>
              <p>48V/60V/20-30Ah</p>
              <p>3000 W</p>
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">Power</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
              <p>Battery Type</p>
              <p>Range</p>
              <p>Charging Time</p>
            </div>
            <div className="detail-box-3">
              <p>Gel and Lithium-ion Battery</p>
              <p>75-85 km</p>
              <p>3 to 4 Hours</p>
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">CHASSIS/ BRAKE/ SUSPENSIO</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
  
            <p>Front Brake</p>
            <p>Rear Brake</p>
           
            </div>
            <div className="detail-box-3">
        
            <p>Drum Brake</p> 
            <p>Drum Brake</p> 
          
            </div>
          </div>
        </div>


      

      </div>
      <br />
      <br />
      <div>
        
        {Modal && (
            <div className="modal-back"> 
          <div className="modal-box">
            <div className="overlay"></div>
            <i onClick={toggleModal} class="fa-solid fa-xmark"></i>
            <h1 className="input-txt-1">Full Name</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Phone Number </h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Email</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Message</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Product Name</h1>
            {/* <input  type="text" placeholder="" /> */}
            <select className="delership-input-1" name="" id="">
              <option value="">XGT X-One</option>
            </select>

            <div className="btn-box-delership">
              <input
                className="btn-delearship"
                type="button"
                value="Order Now"
                />
            </div>
                </div>
          </div>


        )}
      </div>
    </div>
  );
}

export default ProductDetail;
