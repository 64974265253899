import React from "react";
import "./SignInPage.css";
import bike from '../../templateOneAssets/productDetailsAssets/bike-back.webp'

import icon from '../../templateOneAssets/productDetailsAssets/vector.webp'
import icon1 from '../../templateOneAssets/productDetailsAssets/vector1.webp'
import icon2 from '../../templateOneAssets/productDetailsAssets/vector2.webp'
import icon3 from '../../templateOneAssets/productDetailsAssets/vector3.webp'
import bottom from '../../templateOneAssets/productDetailsAssets/bottom.webp'

const signinpage = () => {
  return (
    <div className="signin-main-box">
      <div className="signin-left-box">
        <div className="left-left-box">
            <div className="sign-in-section"><a className="link-a-selected"  href=""><img className="icon-img" src={icon} alt="" /> Sign In</a></div>
            <div className="sign-in-section1"><a className="link-a"   href=""> <img className="icon-img" src={icon1} alt="" /> Delivery Address</a></div>
            <div className="sign-in-section1"><a className="link-a"   href=""> <img className="icon-img" src={icon2} alt="" /> Overview</a></div>
            <div className="sign-in-section1"><a className="link-a"   href=""> <img className="icon-img" src={icon3} alt="" /> Payment</a></div>
        </div>
        <div className="left-right-box">
            {/* <div className="left-center-box"> */}
                     <h1 className="text-sign-in">Please enter your mobile number here.</h1>
                     <input className="input-sign-in" type="text" />
                     <button className="submit-btn-signin"> Submit</button>
            {/* </div> */}
            <img className="bottom-sign-in" src={bottom} alt="" />
        </div>
      </div>

      <div className="signin-right-box">
        <img className="img-bike-back" src={bike} alt="" />
        <div className="">
        <h1  style={{color: '#F49225'}}>KOMAKI <span style={{color: 'black'}}>
            Venice
            </span> 
            </h1>
        <h2>Price :</h2>
        <h1>Color :</h1>
        <p className="signin-right-p">Lorem ipsum dolor olestias doloremque quae quisquam rem magni minus.</p>
        </div>
      <div>
        <div>
            <h1 className="sign-in-right-h">Total MRP</h1>
            <h1 className="sign-in-right-h">85,000</h1>
        </div>
        <div>
            <h1 className="sign-in-right-h">Discount on MRP</h1>
            <h1 className="sign-in-right-h">-5,000</h1>
        </div>
      </div>
      <div>
        <div></div>
      </div>

      </div>
    </div>
  );
};

export default signinpage;
