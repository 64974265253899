import React, { useEffect, useState } from "react";
import ProductDetailCard from "../../templateOneComponents/productdetailcard/ProductDetailCard";
import Product from "../../templateOneComponents/product/Product";
import ProductBottom from "../../templateOneComponents/productBottom/ProductBottom";
import '../productdetail/productdetail.css';

import HomeCard from '../../templateOneComponents/homeCard/HomeCard';
import pdone from '../../templateOneAssets/productDetailsAssets/ly-1.webp';
import pdtwo from '../../templateOneAssets/productDetailsAssets/ly-2.webp';
import pdthree from '../../templateOneAssets/productDetailsAssets/ly-3.webp';
import pdfour from '../../templateOneAssets/productDetailsAssets/ly-4.webp';



import { FaStarHalfAlt, FaArrowRight, FaRupeeSign } from "react-icons/fa";
import imageone from '../../templateOneAssets/homePageAssets/bike-img-5.webp';

function ProductDetail() {
  const [Modal, setModal ] = useState(false);

  const toggleModal = ()=> {
   setModal(!Modal)
   document.getElementById('root-1').classList.toggle('back-blur');
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 
  return (
     // <div>
     <div>
     {/* <div className='ss-container'> */}
     <div className="ss-parent margin-top">
       <div className="ss-p15">
         <img className="bike-image" src={imageone} alt="" />
         <Product
           leftTxt="Ride Towards an"
           evName="LY"
           
           rightTxt="Eco-friendly world!"
           />
       </div>
       <div className="pb-1">
       

<div className="pb-bottom"  >
       
       <div className="pb-battery">
           <h2 className='pb-heading'>Price :</h2>
           <div className="price-contianer">
         <span className="price"><FaRupeeSign/>1,15,154</span>
         {/* <span className="old-price">90000</span> */}
       </div>
       </div>
       <div className="pb-color">
               <h4 className="pb-heading">Color :</h4>
           <div className='pb-color-container'>

           <div className="color-2"><i class="fa-solid fa-check"></i></div>
           <div className="color-3"></div>
           <div className="color-4"></div>
           <div className="color-5"></div>
           <div className="color-6"></div>
           <div className="color-7"></div>
           <div className="color-8"></div>
           </div>
       </div>
       <div className='pb-right-1'>

       {/* <div className="pb-charge">
           <h2 className='pb-heading'>Consumption</h2>
           <h3 className="pb-chrgeTime pb-text">
               
           </h3>
       </div> */}
       <div className="p-bottom-12">
       {/* <div className='order'> */}
            <button onClick={toggleModal} className="order-btn"> Order Now</button>     
           {/* <div className='p-reserve'>Reserve for ₹999 <i style={{marginLeft: "5px"}} class="fa-solid fa-arrow-right"></i></div> */}
       {/* </div> */}
   </div>
       
       </div>
   </div>
</div>
</div>

      <div className="section-1-top">
        <h1 className="header-d-1">Description :</h1>
        <p className="paragraph-1">
        The signature style of a new era. Streamlined
Understated. Brilliant. Every line, surface, and
detail is finished to perfection for a more subtle
yet unmistakable statement on the road.
        </p>
        <h1 className="header-d-1">Warranty :</h1>
        <p className="paragraph-1">
          Komaki warrants all its motorcycles for a period of 5 years or 70000
          Km and scooters for a period of 5 years or 50000 Km, whichever is
          earlier, from the date of purchase.
        </p>

<div className="section-2-top">

        <h1 className="header-d-1"> Highlights:</h1>
        <ul  className="paragraph-1">
          <li>LOCK BY TELESCOPIC REGENERATIVE</li>
          <li>CRUISE ANTI BRAKING CONTROL THEFT LOCK</li>
          <li>REMOTE SHOCKER </li>
          <li>COMFORTABLE DISC BRAKE NOISE FREE MOBILE</li>
        </ul>
</div>
        <h1 className="header-d-1">Product Description :</h1>

</div>
        <div className="product-detail-section">

        <ProductDetailCard  
             pdImg={pdone} 
             classlist='section-div-inner'
             pdHeading="POWER EQUIVALENT OF A 100cc CLASS"
             pdDesc="Equipped with same power train performance to 125cc class, KOMAKI LY genetically wired to a more efficient roaming. "
             />

        <ProductDetailCard 
             pdImg={pdtwo}
             classlist='section-div-inner1'
          pdHeading="FREE THE LIMITATION"
          pdDesc="Better motor output for you to break the boundaries. Turn it up, speed it up, gain more while you go further."
          />

        <ProductDetailCard 
             pdImg={pdthree}
             classlist='section-div-inner'
             pdHeading="Superior Suspension"
             pdDesc=" A pair of thicker, more robust front rear suspensions, coupled with custom dampening controls, provide the comfort in speed so you
             can take on anything."
             />

        <ProductDetailCard 
             pdImg={pdfour}
             classlist='section-div-inner1'
             pdHeading="Double Disc Brake System"
          pdDesc="Also found in heavy duty motorcycles, the Double Disc brake system monitors the front and rear wheel at a frequency of 200 times per
          second to prevent an imminent wheel lock.
          
          Weighs only 580-gram, this device balances performance & safety in all riding situations.
          
          "
          />

          </div>
      
          <div className="section-1-top" style={{marginTop :'60px', marginBottom:'-10px'}}>
<h1 className="header-d-1">Similar Products :</h1>
</div>
<section className="h-section-6">
        <div className="h-section-6-card">
          <div className="h-section-6-bg-1">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev1} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Tn95"
            evName="Komaki TN-95"
            rating={4.3}
            review="2k"
            price="1,49,322"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-2">
            {/* <div className="h-section-6-ev">
              <img  id='sec6ev2' src={sec6ev2} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-VeinceEco"
            evName="Komaki venice eco"
            rating={4.2}
            review="2k"
            price="1,05,525"
            // oldPrice="1,05,525"
          />
        </div>

        <div className="h-section-6-card">
          <div className="h-section-6-bg-3">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev3} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-DT-3000"
            evName="Komaki DT-3000"
            rating={4.2}
            review="2k"
            price="1,38,858"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-4">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev4} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Ly"
            evName="Komaki LY"
            rating={4.2}
            review="2k"
            price="1,15,154"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-5">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev5} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Flora"
            evName="Komaki flora"
            rating={4.2}
            review="2k"
            price="1,04,475"
            // oldPrice="85,000"
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-6">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev6} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/productdetail-Veince"
            evName="Komaki Venice"
            rating={4.2}
            review="2k"
            price="1,38,600"
            // oldPrice="1,38,600"
          />
        </div>
      </section>

<div className="deteil-section-1">

        <div> 
          <h1 className="heading-background">Technical Specification :</h1>
          <div className="detail-box-1">
            <div className="detail-box-2">
              <p>Model</p>
              <p>Motor Type</p>
              <p>Battery Consumption</p>
              <p>Transmission</p>
            </div>
            <div className="detail-box-3">
              <p>Komaki LY</p>
              <p>Interior Permanent Magnet</p>
              <p>1.5 unit per charge</p>
              <p>Direct Hub Motor</p>
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">Power</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
              <p>Battery Type</p>
              <p>Range</p>
              <p>Charging Time</p>
            </div>
            <div className="detail-box-3">
              <p>Lithium battery</p>
              <p>70-90 km</p>
              <p>4 to 5 Hours</p>
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">CHASSIS/ BRAKE/ SUSPENSIO</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
            <p>CHASSIS</p>
            <p>Front Brake</p>
            <p>Rear Brake</p>
      
            </div>
            <div className="detail-box-3">
            <p>Highly Resistant Steel</p>
            <p>Disc Brake</p>
            <p>Disc Brake</p> 

            </div>
          </div>
        </div>




      </div>
      <br />
      <br />
      <div>
        
        {Modal && (
            <div className="modal-back"> 
          <div className="modal-box">
            <div className="overlay"></div>
            <i onClick={toggleModal} class="fa-solid fa-xmark"></i>
            <h1 className="input-txt-1">Full Name</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Phone Number </h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Email</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Message</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Product Name</h1>
            <select className="delership-input-1" name="" id="">
              <option value="">LY</option>
            </select>

            <div className="btn-box-delership">
              <input
                className="btn-delearship"
                type="button"
                value="Order Now"
              />
            </div>
          </div>
            </div>


        )}
      </div>
    </div>
  );
}

export default ProductDetail;
