import React from "react";
import "./homeStyle.css";
import { Link } from "react-router-dom";

//components
import HomeCard from "../../templateOneComponents/homeCard/HomeCard";
import NewsCard from "../../templateOneComponents/newsCard/NewsCard";

import text123 from "../../templateOneAssets/homePageAssets/text2.webp";

//images
import sec1Ev from "../../templateOneAssets/homePageAssets/hsection1ev.webp";

import sec2Ev1 from "../../templateOneAssets/homePageAssets/sec-2-ev-1.webp";
import sec2Ev2 from "../../templateOneAssets/homePageAssets/sec-2-ev-2.webp";
import sec2Ev3 from "../../templateOneAssets/homePageAssets/sec-2-ev-3.webp";
import sec2Ev4 from "../../templateOneAssets/homePageAssets/sec-2-ev-4.webp";
import sec3Ev3 from "../../templateOneAssets/homePageAssets/sec-3-ev-1.webp";
import sec4Ev1 from "../../templateOneAssets/homePageAssets/sec-4-ev-1.webp";
import ranger from "../../templateOneAssets/homePageAssets/sec-4-right-ranger.webp";
import sec5Ev1 from "../../templateOneAssets/homePageAssets/sec-5-ev-1.webp";

import sec6ev1 from "../../templateOneAssets/homePageAssets/sec-6-ev-1.webp";
import sec6ev2 from "../../templateOneAssets/homePageAssets/sec-6-ev-2.webp";
import sec6ev3 from "../../templateOneAssets/homePageAssets/sec-6-ev-3.webp";
import sec6ev4 from "../../templateOneAssets/homePageAssets/sec-6-ev-4.webp";
import sec6ev5 from "../../templateOneAssets/homePageAssets/sec-6-ev-5.webp";
import sec6ev6 from "../../templateOneAssets/homePageAssets/sec-6-ev-6.webp";

import sec7ev1 from "../../templateOneAssets/homePageAssets/sec-7-ev-1.webp";

import sec8ev1 from "../../templateOneAssets/homePageAssets/sec-8-ev-1.webp";
import sec8ev2 from "../../templateOneAssets/homePageAssets/sec-8-ev-2.webp";
import sec8ev3 from "../../templateOneAssets/homePageAssets/sec-8-ev-3.webp";
import textmore from "../../templateOneAssets/homePageAssets/text-more.webp";

const HomePage = () => {
  return (
    <div className="sections">
      <section className="h-section-1">
        <div className="h-section-1-container">
          <div className="h-section-1-left">
            <h1 className="welcome">WELCOME TO</h1>
            <h1 className="eg">Eg Prestige</h1>
            <p className="h-section-1-para">
              Lorem ipsum dolor sit amet. Aliquam in elementum tellus. dolor sit
              amet. Aliquam in elementum tellus.
            </p>
            <button className="book-now">Book Now</button>
          </div>
          <div className="h-section-1-right">
            {/* <img className='h-section-1-ev' src={sec1Ev} alt="" /> */}
          </div>
        </div>
      </section>

      {/* section 2 starts here */}
      <section className="h-section-2">
        <div className="h-section-2-container">
          <div className="h-section-2-top">
            <img className="w-100 w-50 w-85" src={text123} alt="" />
          </div>
          <div className="h-section-2-bottom">
            <div className="sec-2-ev-container">
              {" "}
              <img src={sec2Ev1} alt="" className="sec-2-ev-img sec-1-ev-img" />
            </div>
            <div className="sec-2-ev-container">
              {" "}
              <img src={sec2Ev2} alt="" className="sec-2-ev-img sec-2-ev-img" />
            </div>
            <div className="sec-2-ev-container">
              {" "}
              <img src={sec2Ev3} alt="" className="sec-2-ev-img sec-3-ev-img" />
            </div>
            <div className="sec-2-ev-container">
              {" "}
              <img src={sec2Ev4} alt="" className="sec-2-ev-img sec-4-ev-img" />
            </div>
          </div>
        </div>
      </section>

      {/* //section 3 starts here */}

      <section className="h-section-3">
        <div className="h-section-3-parent">
          <div className="h-section-3-container">
            <div className="h-section-3-left">
              <h2 className="h-section-3-bth">Bear the Heat!!!</h2>
              <h1 className="komaki-1">KOMAKI</h1>
              <h2 className="h-section-3-ve">Veince ECO</h2>
              <h1 className="komaki-0">RIDE</h1>
              <h1 className="komaki-1">LIKE</h1>
              <h2 className="h-section-3-no">No Other</h2>
            </div>
            <div className="h-section-3-center">
              {/* <div className="h-section-3-ev-container"><img src={sec3Ev3} alt="" className="h-section-3-ev-img" /></div> */}
            </div>
            <div className="h-section-3-right">
              <h2 className="h-section-3-aw">Available with</h2>
              <h1 className="komaki-1">LIFE04</h1>
              <h2 className="komaki-5">
                App <span className="h-section-3-bo">based on</span>
              </h2>
              <h2 className="h-section-3-smart">Smart battery.</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="h-section-4">
        <div className="h-section-4-container">
          <div className="h-section-4-left">
            <div className="h-section-4-ev-container">
              {/* <img src={sec4Ev1} alt="" className="h-section-4-ev-img" /> */}
            </div>
          </div>
          <div className="h-section-4-right">
            <img className="w-100 w-50" src={ranger} alt="" />
            <img
              style={{ marginTop: "-10px" }}
              className="w-100 w-50"
              src={textmore}
              alt=""
            />
            <p className="h-section-4-para">
              KOMAKI RANGER is more than a motorcycle. Its attractive look,
              amazing balance, smoothest ride, extra ordinary features make a
              difference. Once you ride it you never forget it. Make every day
              special with KOMAKI RANGER.
            </p>
            <button className="explore-btn">Explore More</button>
          </div>
        </div>
      </section>

      {/* section 5 starts here */}
      <section className="h-section-5">
        <div className="h-section-5-container">
          <div className="h-section-5-left">
            <h1 className="komaki-3">Feel The Power</h1>
            <h1 className="komaki-4">KOMAKI</h1>
            <h2 className="txt-white">DT 3000</h2>

            <button className="explore-btn">Explore Now</button>
          </div>
          <div className="h-section-5-center">
            <h2 className="txt-white">
              Get <br /> Ready for
            </h2>
            <h1 className="komaki-4">Powerful</h1>
            <h1 className="komaki-4">Ride</h1>
          </div>
          <div className="h-section-5-right">
            {/* <img className='w-100' src={sec5Ev1} alt="" /> */}
          </div>
        </div>
      </section>

      {/* section6 starts here */}
      <div className="txt-btn-box">
        <h1 className="f-s-text">Featured Scooters</h1>

        <Link to="/smart-scooters">
          <button className="view-all-btn">View All</button>
        </Link>
      </div>
      <section className="h-section-6">
        <div className="h-section-6-card">
          <div className="h-section-6-bg-1">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev1} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Tn95"
            evName="Komaki TN-95"
            rating={4.3}
            review="2k"
            price="1,49,322"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-2">
            {/* <div className="h-section-6-ev">
              <img  id='sec6ev2' src={sec6ev2} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-VeinceEco"
            evName="Komaki venice eco"
            rating={4.2}
            review="2k"
            price="1,05,525"
            // oldPrice="1,05,525"
          />
        </div>

        <div className="h-section-6-card">
          <div className="h-section-6-bg-3">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev3} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-DT-3000"
            evName="Komaki DT-3000"
            rating={4.2}
            review="2k"
            price="1,38,858"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-4">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev4} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Ly"
            evName="Komaki LY"
            rating={4.2}
            review="2k"
            price="1,15,154"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-5">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev5} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Flora"
            evName="Komaki flora"
            rating={4.2}
            review="2k"
            price="1,04,475"
            // oldPrice="85,000"
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-6">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev6} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/productdetail-Veince"
            evName="Komaki Venice"
            rating={4.2}
            review="2k"
            price="1,38,600"
            // oldPrice="1,38,600"
          />
        </div>
      </section>

      {/* section 7 starts here */}
      <section className="h-section-7">
        <div className="h-section-7-join">
          <div className="h-section-7-join-left">
            <h1 className="h-section-7-wj">WHY JOIN US ?</h1>
            <p className="h-section-7-para">
              FOR OVER 32 YEARS <br /> WE HAVE BEEN SERVING TO BUILD <br /> TO A
              BETTER WORLD
            </p>
            <button className="explore-btn">Join now</button>
          </div>
        </div>
        <div className="h-section-7-blogs">
          <h1 className="h-section-7-blogs1">BLOGS</h1>
          <p className="h-section-7-enw">
            Explore new ways of <br /> life
          </p>
          <button className="explore-btn">Explore More</button>
        </div>
      </section>

      {/* section 8 start here */}
      <h1 className="komaki-0">NEWS SECTION</h1>
      <section className="h-section-8">
        <div className="h-section-8-news-container">
          <NewsCard
            image={sec8ev1}
            heading="HT NEWS"
            description="lorem jadk ajdk ajdk jadkj ajdkadk dak ajdk jadkj ajdkadk dak"
          />
          <NewsCard
            image={sec8ev2}
            heading="ZEE NEWS"
            description="lorem jadk ajdk ajdk jadkj ajdkadk dak ajdk jadkj ajdkadk dak"
          />
          <NewsCard
            image={sec8ev3}
            heading="BHARAT TIMES"
            description="lorem jadk ajdk ajdk jadkj ajdkadk dak ajdk jadkj ajdkadk dak"
          />
        </div>
      </section>
    </div>
  );
};

export default HomePage;
