import React from "react";
import "./dealershipStyle.css";

import icon11 from "../../templateOneAssets/dealershipAssets/icon-11.png";
import icon12 from "../../templateOneAssets/dealershipAssets/icon-12.png";
import icon13 from "../../templateOneAssets/dealershipAssets/icon-13.png";
import icon14 from "../../templateOneAssets/dealershipAssets/icon-14.png";
import icon15 from "../../templateOneAssets/dealershipAssets/icon-15.png";
import icon16 from "../../templateOneAssets/dealershipAssets/icon-16.png";

const DealershipPage = () => {
  return (
    <div>
      <div className="dealership-top"></div>

      <div className="w-80">
        <h1 className="dealership-green-text">Advantage to join Eg Prestige</h1>
        <h2 className="dealership-green-text-1">Electric Vehicle Division</h2>

        <p className="dealership-green-text-2">
          KLB KOMAKI has ventured in the ELECTRIC VEHICLE BUSINESS since 2016.
          We are one of the most reliable and well equipped manufacturerszof
          Electric Vehicles with best services at nominal prices. KOMAKI
          ELECTRIC VEHICLE DIVISON includes E-Rickshaw, E-Scooty & E-Loader. All
          vehicles have been manufactured with highest quality spare parts with
          arrangement for excellent customer service.
        </p>

        <h1 className="dealership-green-text">
          Right time to start business with EG Prestige
        </h1>

        <h2 className="dealership-green-text-1">
          We wish you out to grow in life and business
        </h2>

        <p className="dealership-green-text-2">
          The Electric scooter industry is affordable, sustainable, and
          growing.A new way of life is emerging and you can make the most of it.
        </p>
      </div>

      <div className="delership-center">
        <div className="delership-center-left">
          <div className="d-c-main">
            <h1 className="d-c-m">
              Want to know how could EG Prestige give high business
            </h1>
          </div>

          <div className="input-txt-btn">
            <h1 className="input-txt-1">Full Name</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Phone Number </h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">State</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">City</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Pincode</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Address</h1>
            <input className="delership-input-1-1" type="text" />
            <div className="btn-box-delership">
              <input className="btn-delearship" type="button" value="NEXT" />
            </div>
          </div>
        </div>
        <div className="delership-center-right ">
          <h1 className="mt-25">DEALERSHIP WITH EG Prestige</h1>
          <p className="dealership-green-text-2 mt-10">
            We have great expectations from our Dealers. We consider them as our
            family member, not only we consider them , we treat them also in
            such manner. Similarly we expect Komaki to be treated by them in
            such manner as Komaki treats them. We have following expectations
            from our beloved dealers:-
          </p>
          <div className="dealership-green-text-2 mt-20">
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
            <li>Straightforward and reasonable managing.</li>
          </div>
        </div>
      </div>
      
<div style={{backgroundColor: "#F6F6F6"}}>

      <div className="delership-center-lower">

        <div className="delership-center-lower-1">
          <img className="lower-image" src={icon11} alt="" />
          <h1 className="lower-text-1">Range</h1>
          <p className="lower-paragraph-1">
            Komaki offers a wide range of electric bikes that are reasonable for
            all individuals. It is a helpful and quick method for transport for
            the younger buyer helping them reach schools and universities. For
            working experts and very affordable. Komaki Electric Scooters also
            work very well for the elderly buyers offering them a safe ride.
          </p>
        </div>
        <div className="delership-center-lower-1">
          <img className="lower-image" src={icon12} alt="" />
          <h1 className="lower-text-1">Reassurance</h1>
          <p className="lower-paragraph-1">
            Komaki offers a wide range of electric bikes that are reasonable for
            all individuals. It is a helpful and quick method for transport for
            the younger buyer helping them reach schools and universities. For
            working experts and very affordable. Komaki Electric Scooters also
            work very well for the elderly buyers offering them a safe ride.
          </p>
        </div>
        <div className="delership-center-lower-1">
          <img className="lower-image" src={icon13} alt="" />
          <h1 className="lower-text-1">Remuneration</h1>
          <p className="lower-paragraph-1">
            Komaki offers a wide range of electric bikes that are reasonable for
            all individuals. It is a helpful and quick method for transport for
            the younger buyer helping them reach schools and universities. For
            working experts and very affordable. Komaki Electric Scooters also
            work very well for the elderly buyers offering them a safe ride.
          </p>
        </div>
        <div className="delership-center-lower-1">
          <img className="lower-image" src={icon14} alt="" />
          <h1 className="lower-text-1">Respect</h1>
          <p className="lower-paragraph-1">
            Komaki offers a wide range of electric bikes that are reasonable for
            all individuals. It is a helpful and quick method for transport for
            the younger buyer helping them reach schools and universities. For
            working experts and very affordable. Komaki Electric Scooters also
            work very well for the elderly buyers offering them a safe ride.
          </p>
        </div>
        <div className="delership-center-lower-1">
          <img className="lower-image" src={icon15} alt="" />
          <h1 className="lower-text-1">Reach</h1>
          <p className="lower-paragraph-1">
            Komaki offers a wide range of electric bikes that are reasonable for
            all individuals. It is a helpful and quick method for transport for
            the younger buyer helping them reach schools and universities. For
            working experts and very affordable. Komaki Electric Scooters also
            work very well for the elderly buyers offering them a safe ride.
          </p>
        </div>
        <div className="delership-center-lower-1">
          <img className="lower-image" src={icon16} alt="" />
          <h1 className="lower-text-1">Recognition</h1>
          <p className="lower-paragraph-1">
            Komaki offers a wide range of electric bikes that are reasonable for
            all individuals. It is a helpful and quick method for transport for
            the younger buyer helping them reach schools and universities. For
            working experts and very affordable. Komaki Electric Scooters also
            work very well for the elderly buyers offering them a safe ride.
          </p>
        </div>
</div>
      </div>

      <div className="delership-center-end w-80">
        <h1 className="dealership-green-text">
          ADDITIONAL SERVICES FOR OUR DEALERS:
        </h1>
        <h2 className="dealership-green-text-1">
          As parts of sales promotion, following additional services shall be
          offered to dealers:-
        </h2>
        <p className="dealership-green-text-2">
          • We help you to a market by providing material, pamphlets &posters. •
          We have attractive features and the quality Spare parts. • We have 1
          year warranty on our Major parts. • We provide after sales support &
          Feedback facilities. • Cross Roads Services are provided (under
          process). -Under that, at any place a mechanic will be provided by
          customer Care. -You don’t need to go to mechanic, He will come to you.
          • We provide Financing agreement for our retail customers by corporate
          tie up with various banks. • At every step we are with you to increase
          Sales.
        </p>
      </div>
    </div>
  );
};

export default DealershipPage;
