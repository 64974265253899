import React, { useEffect, useState } from "react";
import ProductDetailCard from "../../templateOneComponents/productdetailcard/ProductDetailCard";
import Product from "../../templateOneComponents/product/Product";
import ProductBottom from "../../templateOneComponents/productBottom/ProductBottom";
import '../productdetail/productdetail.css';

import HomeCard from '../../templateOneComponents/homeCard/HomeCard';
import pdone from '../../templateOneAssets/productDetailsAssets/ranger-1.webp';
import pdtwo from '../../templateOneAssets/productDetailsAssets/ranger-2.webp';
import pdthree from '../../templateOneAssets/productDetailsAssets/ranger-3.webp';
import pdfour from '../../templateOneAssets/productDetailsAssets/ranger-41.webp';

import { FaStarHalfAlt, FaArrowRight, FaRupeeSign } from "react-icons/fa";
import imageone from '../../templateOneAssets/homePageAssets/bike-img-6.webp';

function ProductDetail() {
  const [Modal, setModal ] = useState(false);

  const toggleModal = ()=> {
   setModal(!Modal)
   document.getElementById('root-1').classList.toggle('back-blur');
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div>
    <div>
      {/* <div className='ss-container'> */}
      <div className="ss-parent margin-top">
        <div className='ss-p16'>
        <img className="bike-image5" src={imageone} alt="" />
          <Product
            leftTxt='India’s first'
            evName='Ranger'
          
            rightTxt='Electric cruiser'
          />
        </div>
        <div className="pb-1">
          
        <div className="pb-bottom"  >
        
        <div className="pb-battery">
            <h2 className='pb-heading'>Price :</h2>
            <div className="price-contianer">
          <span className="price"><FaRupeeSign/>2,05,000
</span>
          {/* <span className="old-price">90000</span
n> */}
        </div>
        </div>
        <div className="pb-color">
                <h4 className="pb-heading">Color :</h4>
            <div className='pb-color-container'>

            <div className="color-2"><i class="fa-solid fa-check"></i></div>
            <div className="color-3"></div>
            <div className="color-4"></div>
            <div className="color-5"></div>
            <div className="color-6"></div>
            <div className="color-7"></div>
            <div className="color-8"></div>
            </div>
        </div>
        <div className='pb-right-1'>

        {/* <div className="pb-charge">
            <h2 className='pb-heading'>Consumption</h2>
            <h3 className="pb-chrgeTime pb-text">
                
            </h3>
        </div> */}
        <div className="p-bottom-12">
        {/* <div className='order'> */}
             <button onClick={toggleModal} className="order-btn"> Order Now</button>     
            {/* <div className='p-reserve'>Reserve for ₹999 <i style={{marginLeft: "5px"}} class="fa-solid fa-arrow-right"></i></div> */}
        {/* </div> */}
    </div>
        
        </div>
    </div>
</div>
</div>

      <div className="section-1-top">
        <h1 className="header-d-1">Description :</h1>
        <p className="paragraph-1">
        KOMAKI RANGER is more than a
motorcycle. Its attractive look, amazing
balance, smoothest ride, extra ordinary
features make a difference. Once you
ride it you never forget it. Make every
day special with KOMAKI RANGER.
Showstopping
Vintage Details
and Pure Rock
and Roll Style.
Authentic heritage meets modern technology
for power, style and the unadulterated riding
experience - around town or along country
roads.
        </p>
        <h1 className="header-d-1">Warranty :</h1>
        <p className="paragraph-1">
          Komaki warrants all its motorcycles for a period of 5 years or 70000
          Km and scooters for a period of 5 years or 50000 Km, whichever is
          earlier, from the date of purchase.
        </p>

<div className="section-2-top">

        <h1 className="header-d-1"> Highlights:</h1>
        <ul  className="paragraph-1">
          <li>Comfortable Seating</li>
          <li>Inclusive Dual Storage Space</li>
          <li>Dual Passenger,Footrest </li>
          <li>LED + Additional</li>
        </ul>
</div>
        <h1 className="header-d-1">Product Description :</h1>

</div>
        <div className="product-detail-section">

        <ProductDetailCard  
             pdImg={pdone} 
             classlist='section-div-inner'
             pdHeading="Power with
             Unprecedented
             Backup"
             pdDesc="Equipped with same power train
             performance as ic engines accompanied
             with India's biggest battery pack.
             Komaki Ranger is genetically engineered
             to a more efficient roaming."
             />

        <ProductDetailCard 
             pdImg={pdtwo}
          pdHeading="CBS
          Double Disc"
          classlist='section-div-inner1'
          pdDesc="Double Disc brake system
          monitors the front and rear wheel
          at a frequency of 200 times per
          second to prevent an imminent
          wheel lock.
          Weighs only 580-gram, this device
          balances performance & safety in
          all riding situations
           "
          />

        <ProductDetailCard 
             pdImg={pdthree}
             classlist='section-div-inner'
             pdHeading="Superior Suspension"
             pdDesc="A pair of thicker, more robust
             front rear suspensions,
             coupled with custom
             dampening controls,
             provide the comfort
             in speed so you can take
             on anything"
             />

        <ProductDetailCard 
             pdImg={pdfour}
             classlist='section-div-inner1'
             pdHeading="CBS Double Disc"
          pdDesc="dolore magna aliqua. Ut enim ad  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit "
          />

          </div>
      
          <div className="section-1-top" style={{marginTop :'60px', marginBottom:'-10px'}}>
<h1 className="header-d-1">Similar Products :</h1>
</div>
<section className="h-section-6">
        <div className="h-section-6-card">
          <div className="h-section-6-bg-1">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev1} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Tn95"
            evName="Komaki TN-95"
            rating={4.3}
            review="2k"
            price="1,49,322"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-2">
            {/* <div className="h-section-6-ev">
              <img  id='sec6ev2' src={sec6ev2} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-VeinceEco"
            evName="Komaki venice eco"
            rating={4.2}
            review="2k"
            price="1,05,525"
            // oldPrice="1,05,525"
          />
        </div>

        <div className="h-section-6-card">
          <div className="h-section-6-bg-3">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev3} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-DT-3000"
            evName="Komaki DT-3000"
            rating={4.2}
            review="2k"
            price="1,38,858"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-4">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev4} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Ly"
            evName="Komaki LY"
            rating={4.2}
            review="2k"
            price="1,15,154"
            // oldPrice='85,000'
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-5">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev5} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/ProductDetail-Flora"
            evName="Komaki flora"
            rating={4.2}
            review="2k"
            price="1,04,475"
            // oldPrice="85,000"
          />
        </div>
        <div className="h-section-6-card">
          <div className="h-section-6-bg-6">
            {/* <div className="h-section-6-ev">
              <img src={sec6ev6} alt="" />
            </div> */}
          </div>

          <HomeCard
            producturl="/productdetail-Veince"
            evName="Komaki Venice"
            rating={4.2}
            review="2k"
            price="1,38,600"
            // oldPrice="1,38,600"
          />
        </div>
      </section>

<div className="deteil-section-1">

        <div> 
          <h1 className="heading-background">Technical Specification :</h1>
          <div className="detail-box-1">
            <div className="detail-box-2">
              <p>Model</p>
              <p>Motor Type</p>
              <p>Controller</p>
              <p>Transmission</p>
              <p>Rated Power</p>
              <p>Gear</p>
            </div>
            <div className="detail-box-3">
              <p>Komaki Ranger</p>
              <p>Interior Permanent Magnet, Brushless Motor</p>
              <p>80AMP High Efficiency with Regenerative Braking Transmission</p>
              <p>Hub Motor</p>
              <p>4000 watt</p>
              <p>Eco/Comfort/Sport/Turbo</p>
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">Power</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
              <p>Battery Type</p>
              <p>Battery Capacity</p>
              <p>Charging Time</p>
            </div>
            <div className="detail-box-3">
              <p>Advance Lithium Battery</p>
              <p>72V50AH</p>
              <p>5 to 6 hrs</p>
            </div>
          </div>
        </div>

        <div> 
          <h1 className="heading-background">CHASSIS/ BRAKE/ SUSPENSIO</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
            <p>CHASSIS</p>
            <p>Front Brake</p>
            <p>Rear Brake</p>
            <p>Front Tyre</p>
            <p>Rear Tyre</p>
            </div>
            <div className="detail-box-3">
            <p>Highly Resistant Steel</p>
            <p>270x3 5mm Disc</p>
            <p>240x3 5 mm Disc</p> 
            <p>110/70/17</p>
            <p>110/70/17</p>
            </div>
          </div>
        </div>


        <div> 
          <h1 className="heading-background">DIMENSION</h1>
          <div className="detail-box-1">
          <div className="detail-box-2">
              <p>Dimension</p>
              <p>Wheel base</p>
            </div>
            <div className="detail-box-3">
              <p>2250x745x1165mm</p>
              <p>1480mm</p>
            </div>
          </div>
        </div>

      </div>
      <br />
      <br />
      <div>
        
        {Modal && (
            <div className="modal-back"> 
          <div className="modal-box">
            <div className="overlay"></div>
            <i onClick={toggleModal} class="fa-solid fa-xmark"></i>
            <h1 className="input-txt-1">Full Name</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Phone Number </h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Email</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Message</h1>
            <input className="delership-input-1" type="text" />

            <h1 className="input-txt-1">Product Name</h1>
            <select className="delership-input-1" name="" id="">
              <option value="">Ranger</option>
            </select>

            <div className="btn-box-delership">
              <input
                className="btn-delearship"
                type="button"
                value="Order Now"
                />
            </div>
                </div>
          </div>


        )}
      </div>
    </div>
  );
}

export default ProductDetail;
