import React from 'react';
import { FaStarHalfAlt, FaArrowRight, FaRupeeSign } from "react-icons/fa";
import { Link } from 'react-router-dom';
import './homecardStyle.css';

function HomeCard(props) {
  return (
    <div className='hc-container'>
      {/* <div className="hc-img-container">
        <img className='bg-img' src={props.bgImg} alt="" />
        <div>
        <img className='ev-img' src={props.image} alt="" />
        </div>
      </div> */}
      <h1 className="hc-ev-name">{props.evName}</h1>
      <div className="hc-rating-container">
        <span className="rating-num">{props.rating}</span>
        <FaStarHalfAlt className='star' />
        <span>{props.review}</span>
      </div>
      <div className="hc-bottom">
        <div className="price-contianer">
          <span className="price"><FaRupeeSign/>{props.price}</span>
          <span className="old-price">{props.oldPrice}</span>
        </div>
        <Link  to={props.producturl}>
        <button className='book-btn'>
          Book Now
        </button>
        </Link>
      </div>
      <div className='reserve'>Reserve for ₹999 <FaArrowRight/></div>
    </div>
  )
}

export default HomeCard