import React from 'react';
import Product from '../../templateOneComponents/product/Product';
import './smartscooterStyle.css';

import ssEv1 from '../../templateOneAssets/smartScootersAssets/ss-ev-1.webp';
import ssEv2 from '../../templateOneAssets/smartScootersAssets/ss-ev-2.webp';
import ssEv3 from '../../templateOneAssets/smartScootersAssets/ss-ev-3.webp';
import ssEv4 from '../../templateOneAssets/smartScootersAssets/ss-ev-4.webp';


import ProductBottom from '../../templateOneComponents/productBottom/ProductBottom';

const SmartScootersPage = () => {
  return (


    <div className='ss-container'>
      <div className='ss-parent'>
        <div className='ss-p1'>
          <Product
            leftTxt='Ride Towards an'
            evName='Veince'
            // ev={ssEv1}
            rightTxt='Eco-friendly world!'
            productlink='/productdetail-Veince'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange='90 km/charge'
          chargeTime='1 - 1.5 units/charge'
        />
        </div>





        <div className='ss-parent'>
        <div className='ss-p5'>
          <Product
            leftTxt='Ride like'
            evName='Veince Eco' 
            // ev={ssEv1} 
            rightTxt='No other'
            productlink='/ProductDetail-VeinceEco'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange='80-100 km/charge'
          chargeTime='1.8 - 2 units/charge'
        />
        </div>
      </div>
      </div>



      <div className='ss-parent'>
        <div className='ss-p2'>
          <Product
           productlink='/ProductDetail-Tn95'
            leftTxt='Like a'
            evName='Tn95'
            // ev={ssEv2}
            rightTxt='Bullet!'
          />
        </div>
        <div className="pb-">

        <ProductBottom
          evRange='110 Km Min'
          chargeTime='1.5 Units/charge'
        />
        </div>
      </div>



      <div className='ss-parent'>
        <div className='ss-p3'>
          <Product
           productlink='/ProductDetail-Flora'
            leftTxt='Premium'
            evName='Flora'
            // ev={ssEv3}
            rightTxt='Economy!'
          />
        </div>
        <div className="pb-">

        <ProductBottom
          evRange='75-100 km/charge'
          chargeTime='1.5 Units/charge'
        />
        </div>
      </div>




      <div className='ss-parent'>
        <div className='ss-p6'>
          <Product
           productlink='/ProductDetail-Ly'
            leftTxt='Pay less'
            evName='Ly'
            // ev={ssEv1}
            rightTxt='Ride more!'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange='90 km/charge'
          chargeTime='1.5 Units/charge'
        />
        </div>
      </div>



      <div className='ss-parent'>
        <div className='ss-p7'>
          <Product
           productlink='/ProductDetail-Ranger'
            leftTxt='India’s first'
            evName='Ranger'
            // ev={ssEv1}
            rightTxt='Electric cruiser'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange=
          '200-220 km/charge'
          chargeTime='Advanced Lithium '
        />
        </div>
      </div>




      <div className='ss-parent'>
        <div className='ss-p4'>
          <Product
           productlink='/ProductDetail-DT-3000'
            leftTxt='Get ready for'
            evName='DT-3000'
            // ev={ssEv1}
            rightTxt='Powerful ride!'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange=
          '110-160 km/charge'
          chargeTime='1.5 Units/charge'
        />
        </div>
      </div>
      
      <div className='ss-parent'>
        <div className='ss-p8'>
          <Product
           productlink='/ProductDetail-XGT-X-4'
         leftTxt='Effortleslly'
         evName='XGT X-4'
        //  ev={ssEv1}
         rightTxt='Smooth!'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange=
          '70-80 km/charge'
          chargeTime='1.3 Units/charge'
        />
        </div>
      </div>





      
      <div className='ss-parent'>
        <div className='ss-p9'>
          <Product
           productlink='/ProductDetail-XGT-X-One'
               leftTxt='Let’s Race'
               evName='XGT X-One'
              //  ev={ssEv1}
               rightTxt='New'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange=
          '50-60 km/charge'
          chargeTime='1.1 Units/charge'
        />
        </div>
      </div>





      
      <div className='ss-parent'>
        <div className='ss-p10'>
          <Product
           productlink='/ProductDetail-Cat'
            leftTxt='GO green'
            evName='CAT-2.0'
            // ev={ssEv1}
            rightTxt='GO electric!'
          />
        </div>
        <div className="pb-">
          
        <ProductBottom
          evRange=
          '110-70 km/charge'
          chargeTime='1.1 Units/charge'
        />
        </div>
      </div>
    </div>
    
  


    
  )
}

export default SmartScootersPage