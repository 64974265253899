import React from 'react';
import './productStyle.css';
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

function Product(props) {
    return (
        <div className='p-container'>
            <div className="p-top">
                <h1 className="p-komaki">KOMAKI</h1>
                <h3 className='p-ev-name'>{props.evName}</h3>
            </div>

            <div className="p-center">
                <div className="p-left">
                    <h1 className='p-text'>{props.leftTxt}</h1>
                </div>
                <div className="p-mid">

                    <div className='p-ev-container'>
                        <img style={{display:'none'}} className='p-ev' src={props.ev} alt="" />
                    </div>
                </div>
                <div className="p-right">
                    <div className="p-right-text">

                        <h1 className='p-text  p-text-p'>{props.rightTxt}</h1>
                    </div>

            <Link to={props.productlink} className="p-bottom">
                {/* <div className='order'> */}
                    <button className='order-btn-1'>Order Now</button>
                    <div className='p-reserve'>Reserve for ₹999 <FaArrowRight /></div>
                {/* </div> */}
            </Link>
                </div>
            </div>

        </div>
    )
}

export default Product